<template>
  <div class="mylogo d-flex justify-content-center">
    <b-img
      :src="appLogoImage"
      alt="logo"
    />
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BImg,
  },
  setup() {
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
}
</script>

<style lang="scss" scoped>
.mylogo {
  img {
    width: 100%;
    max-width: 170px;
  }
}
</style>
